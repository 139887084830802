import {Component, Input, OnInit, NgZone, ViewChild, ElementRef, EventEmitter, Output } from "@angular/core";
import { AuthoredExercise, ValidateExercisResult } from "../../types/algebrakit.type";
import { MetadataService } from "../../services/metadata.service";

@Component({
    selector: "app-akit-interaction-editor",
    templateUrl: "./akit-interaction-editor.component.html",
    styleUrls: ["./akit-interaction-editor.component.less"]
})
export class AkitInteractionEditorComponent implements OnInit {
    public exerciseManager: any; 
    public _exerciseId: string;
    public _refId: string;
    public interactionBlacklist: string = '';

    @ViewChild('preview', {static: false}) previewElement: ElementRef;

    @Input() spec: AuthoredExercise;
    @Input() refId: string;
    @Input() allowAssets: boolean = true;

    // @Output() clearExercise: EventEmitter<void> = new EventEmitter<void>();
    // @Output() updateExerciseId: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private akitService: MetadataService,
        private _ngZone: NgZone
    ) {}

    async ngOnInit() {
        if(!this.allowAssets) {
            this.interactionBlacklist = '["STATISTICS"]';
        }

        return this.createExerciseManager(this.spec, this.refId);
    }

    createExerciseManager(spec?: AuthoredExercise, refId?:string) {
        return this.akitService
        .getExerciseManager(spec)
        .then(mgr => {
            this.exerciseManager = mgr;
            this._refId = refId;
            if(!refId) {
                //get the first interaction in the exercise
                let elmsObj = mgr.listElements();
                let elementWithInteractions = Object.keys(elmsObj)
                .map(key => elmsObj[key])
                .find(elm => elm.length>0);

                if(elementWithInteractions) {
                    this._refId = elementWithInteractions[0];
                }
            }
            this._exerciseId = mgr.getExerciseId();
        });
    }

    validateExercise(): Promise<ValidateExercisResult> {
        return this.exerciseManager.inspect()
    }

    getExercise() {
        return this.exerciseManager.getExercise();
    }

    testExercise() {
        this.previewElement.nativeElement.run();
    }

    clearExerciseClicked() {
        this.akitService.createEmptyExercise();
    }
}
