<div *ngIf="_exerciseId">
  <div id="editor-container">
    <akit-interaction-editor
      [attr.exercise-id]="_exerciseId"
      [attr.ref-id]="_refId"
      [attr.interaction-blacklist]="interactionBlacklist"
      [attr.allow-assets]="allowAssets"
    >
    </akit-interaction-editor>
  </div>

  <div id="button-container">
    <button class="form-control" (click)="testExercise()">Test</button>
    <button class="form-control" (click)="clearExerciseClicked()">New</button>
  </div>
  <div id="preview-container">
    <akit-exercise-preview  #preview [attr.exercise-id]="_exerciseId" show-run-button="false"></akit-exercise-preview>
  </div>
</div>
